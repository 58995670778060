import "src/components/organisms/WordPress/SingleBlog.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/SingleBlog.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71WwXKbMBC9+yt2nOlMOhMIEENsemmbpoceMp3pFwhYY8UgUUmOnXT87x0UwAJk1zk0cFt29Xbfvl3hrnaL5W/hwZ8JQEZlVZDnGJYF7j5NAEhBc+ZQhaWMIUWmUNTmkoicMifhSvEyhkBgaZgVr2IIG1tFsoyyfOSbcJGh6Mx+tQPJC5rBxf2X+q19Ul5sSubkpGrj9hP3NV/fnu/jRiq6fHZSzhQy9frBkYoIdaKcLc3UKgbf8z4MYWfurA8c2IC7rzf9r5QVlKGTFDxd1ycvOVOOpC8Yg++2DGnjkpS0eI5h+sAVh1+ESfjxc3oFkjDpSBR0qUkj6ToXfMMyJ+UFFzFc3H0L7oL7Jm1t+a4fg/sWDAL3psHcrqhCR1YkxRgY3wpSGU0RJKMbGcOsMmubvXttC/28R22hXU4DnbcIR2R0Qq7RqfNj8DRXtVQzKjBVlLO4Oa7+8uJQluEuhsBQa9SRvEKar5RpUbhTjs7SzG80HL3Ua341daNmtmUfOhTDhacfC7FBtQOvfo3yb8+cVt00J0G1RWSWybQS36HM+yh9ZXYi/IoJkfCAG5xeQboRkj6hBarHQGDKedvQHXpmiYv/BB640VFwAD2Cbf/9nuSInXNTpJ57a57dVjt7+/AOJ/aQRZzgkgvUyXR93ojicnpNS5KjvE5rqlz5lE8/GoGJjugR0XahV3TQ2FApFHrq9VbwXC88TtvZlXXppDqd4WV208xG55Zpt8HaiNzBNkB7a3RP/FNbrDth+c8TOtf8QOSZi1jPc/9K9ur32BY1lrGnxXNmEavzOKXa7cT/Qli/RsDjUDqhTTi+TeTmfBrjOVZXENqmctZbCethHoPRaqOiV1EOb/T95HOJGSUgU4HIgLAMLkvKnGZZ3EbzavdRY7jmT9zgB6ZBHKny8GHYgVGI/qHzvca8PwCGDeBQ8N0BtkyM+KiJb9df0N1gh1vNMBqR8yaytyAsCIuxX9gtEsOPjP1823mJDXfeJd1XmBuN4tMjjLn+GCuz+/rWQldvOPhxXMTCXkJkg1rbuDr0aP8XhYQSWVMMAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeTag = 'hx9fqr3';
export var bottomDiv = 'hx9fqrc';
export var breadcrumb = 'hx9fqr2';
export var closeTag = 'hx9fqr4';
export var dateDiv = 'hx9fqr6';
export var dateTagWrap = 'hx9fqr7';
export var dateText = 'hx9fqra';
export var day = 'hx9fqr9';
export var middleDiv = 'hx9fqr5';
export var month = 'hx9fqr8';
export var recruitingTag = 'hx9fqre';
export var subTitleClass = 'hx9fqrk';
export var tag = 'hx9fqrg';
export var tagList = 'hx9fqrf';
export var thumbnailDiv = 'hx9fqrd';
export var titleBorder = 'hx9fqri';
export var titleClass = 'hx9fqrj';
export var titleContainer = 'hx9fqrh';
export var titleText = 'hx9fqrb';
export var topDiv = 'hx9fqr0';
export var topInformationDiv = 'hx9fqr1';