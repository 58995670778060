import React from 'react';

import SEO from '../../../utils/seo';
import { useWordPressBlogSettings } from '../../../hooks/useWordPressBlogSettings'
import SingleBlog from '../../../components/organisms/WordPress/SingleBlog';

const EventPage = (props: any) => {
    const slug = props.params.name;
    const { loading: articleLoading, data: blogData } = useWordPressBlogSettings(slug);

    if (articleLoading) return <div></div>;
    const blog = blogData.blogBy;

    return (
        <>
            <SingleBlog blog={blog} />
        </>
    )
}

export default EventPage;
